<template>
    <div>
        <h2>{{ $t("horse.historique") }}</h2>

        <div class="box">
            <CustomTable
				ref="table"
                id_table="tiers_horse_historique_tiers"
                :items="filtredHistorique"
                :busy.sync="table_busy"
                primaryKey="tiershorsepart_id"
                :hide_if_empty="true"
				:display_action_button="!horseFicheReadOnly"
				:checkboxes="!horseFicheReadOnly"
                :hrefsRoutes="config_table_hrefs"
                :callback_one="tiers_checkall"
                :callback_one_deselect="tiers_decheckall"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import HorseMixin from "@/mixins/Horse.js"
    import Tools from "@/mixins/Tools.js"
    import { EventBus } from 'EventBus'

    export default {
        name: "HorseTiersHistorique",
        mixins: [TableAction, Navigation, HorseMixin, Tools],
        props: ['horse_id'],
        data () {
            return {
                tiers_horse: null,
                table_busy: true,
                events_tab: {
                    'TableAction::goToDeleteTiersHorseHistoriqueTiers': (params) => {
                        this.deleteTiersHorse(params.tiershorsepart_id)
                    },
                    'TableAction::goToDeleteMultipleTiersHorseHistoriqueTiers': (params) => {
                        this.deleteMultipleTiersHorse(params.tiershorsepart_id)
                    }
                },
                config_table_hrefs: {
                    'tiers_rs': {
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers_id'
                        }
                    }
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadTiersHistoriqueComponent()
            },
            async loadTiersHistoriqueComponent() {
				this.table_busy = true
                const horse = await this.loadTiersPart(this.horse_id)
                if(horse) {
                    let temp = []
                    horse.tiers_horse.forEach((tiers_horse) => {
                        tiers_horse.tiers_horse_part.forEach((tiers_horse_part) => {
                            temp.push({
                                tiers_rs: tiers_horse.tiers.tiers_rs,
                                tiers_id: tiers_horse.tiers.tiers_id,
                                tiershorsepart_id: tiers_horse_part.tiershorsepart_id,
                                tiershorsepart_propriete: tiers_horse_part.tiershorsepart_propriete,
                                tiershorsepart_pension: tiers_horse_part.tiershorsepart_pension,
                                tiershorsepart_frais: tiers_horse_part.tiershorsepart_frais,
                                tiershorsepart_contract: tiers_horse_part.tiershorsepart_contract,
                                tiershorsepart_date: tiers_horse_part.tiershorsepart_date,
                                tiershorsepart_date_groupby: this.getTrad("horse.a_partir_du") + ' ' + this.formatDate(tiers_horse_part.tiershorsepart_date),
                                tiershorsepart_entraineur: tiers_horse_part.tiershorsepart_entraineur,
								tiershorsepart_jockey: tiers_horse_part.tiershorsepart_jockey
                            })
                        });
                    });

                    this.tiers_horse = temp
                } else {
                    this.tiers_horse = null
                }

				this.$refs.table.resetCachedItems()
                this.table_busy = false
            },
            async deleteTiersHorse(tiershorsepart_id) {
                this.deleteHorseTiersPart(tiershorsepart_id)
                .then(result => {
                    this.loadTiersHistoriqueComponent()
                    EventBus.$emit('DeleteTiersHorseHistoriqueTiersSuccess')
                    this.successToast('toast.info_modif_succes')
                })
                .catch(e => {
					if (typeof(e) === 'string') {
						this.form_message = e
						this.failureToast(e)
					}
					else {
						console.error(e)
					}
                })
            },
            async deleteMultipleTiersHorse(tiershorsepart_id) {
                tiershorsepart_id.split(',').forEach(async (tiershorsepart_id) => {
                    await this.deleteTiersHorse(tiershorsepart_id)
                })
            },

            tiers_checkall(item) {
                if(item.length) {
                    let same_date = this.filtredHistorique.filter(tiers => tiers.tiershorsepart_date == item[0].tiershorsepart_date)

                    this.$refs.table.manual_check(same_date)
                }
            },

            tiers_decheckall(item) {
                if(item.length) {
                    let same_date = this.filtredHistorique.filter(tiers => tiers.tiershorsepart_date == item[0].tiershorsepart_date)

                    this.$refs.table.manual_decheck(same_date)
                }
            },
        },
        computed: {
			horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            },
            filtredHistorique: function() {
                let tmp = this.tiers_horse
                if(!tmp) return null

                tmp.sort((a, b) => {
                    if (Date.parseTz(a.tiershorsepart_date) < Date.parseTz(b.tiershorsepart_date)){
                        return -1;
                    }
                    if (Date.parseTz(a.tiershorsepart_date) > Date.parseTz(b.tiershorsepart_date)){
                        return 1;
                    }
                    return 0;
                })

                return tmp
            }
        },
        components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
        }
    }
</script>
